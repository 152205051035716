<template>
  <div>
    <div class="about d-none d-sm-flex justify-center flex-column">
      <div>
        <div class="page-title primary-text">{{ $t('AppBar.SubMenu.WhoAmI') }}</div>
        <v-card elevation="0" width="60%" color="rgba(0, 0, 0, 0.7)" class="white--text text-h6 font-weight-regular pa-6 mt-4" style="line-height:36px">
          <p>
            {{ $t('Page.About.Desc01') }}
          </p>
          <p>
            {{ $t('Page.About.Desc02') }}
          </p>
          <p>
            {{ $t('Page.About.Desc03') }}
          </p>
        </v-card>
      </div>
    </div>

    
  <div class="about d-block d-sm-none">
      <div class="text-center white--text text-h3">Life Sciences Cells Bank</div>
      <div class="mt-6 text-content white--text">
        <p>
            {{ $t('Page.About.Desc01') }}
          </p>
          <p>
            {{ $t('Page.About.Desc02') }}
          </p>
          <p>
            {{ $t('Page.About.Desc03') }}
          </p>
      </div>
    </div>
    
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
// import Footer from '../../components/Footer.vue'


export default {
  // components: {
  //   Footer
  // },
  mounted(){
        //check anchor
        var hash = window.location.hash;
        if (hash) {
            var _this = this;
            setTimeout(() => {
                _this.goAnchor(hash.replace('#', ''));
            }, 200);
        }
    },
    watch: {
        $route(to){
            var hash = to.hash;
            if (hash) {
                var _this = this;
                setTimeout(() => {
                    _this.goAnchor(hash.replace('#', ''));
                }, 200);
            }
        }
    },

    methods: {
        goAnchor(id) {
            var anchor = document.getElementById(id);
            console.log(anchor);
            // chrome
            document.body.scrollTop = anchor.offsetTop;
            // firefox
            document.documentElement.scrollTop = anchor.offsetTop;
            // safari
            window.pageYOffset = anchor.offsetTop;
        }
    }
}
</script>

<style lang="scss">
  .about {
    min-height: calc(100vh - 289px);
    padding: 50px 120px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.54), rgba(0, 0, 0, 0.54)), url("../../assets/images/banner_bg_a.png") top right;
  }

  @media screen and (max-width: 960px) {
    .about {
      padding: 30px 24px;

      .text-content {
        font-size: 14px;
        line-height: 28px;
      }
    }
  }
</style>
